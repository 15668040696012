class Utils {

    constructor() {
        this.calculationGoneWrongValue = -9999;
    }

    canUseDOM() {
        return !!(
            typeof window !== 'undefined' &&
            window.document &&
            window.document.createElement
        );
    }

    isValidEmail(email) {
        const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return reg.test(email);
    }

    getUUID() {
        return (
            Number(String(Math.random()).slice(2)) +
            Date.now() +
            Math.round(window.performance.now())
        ).toString(36);
    }

    fileSizeToHumanReadable(size) {
        const i = Math.floor(Math.log(size) / Math.log(1024));
        return ((size / Math.pow(1024, i)).toFixed(2) * 1) + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
    }

    calculate(ctx, funcBody, debug) {
        // usage: Calculate({"foo":10, "bar":100}, "foo+bar")
        let newBody = [];
        for (let k in ctx) {
            let i = "let " + k + " = ctx['" + k + "'];";
            newBody.push(i);
        }
        let res = "return function(){ return " + funcBody + " }";
        newBody.push(res);
        try {
            // eslint-disable-next-line no-new-func
            let F = new Function("ctx", newBody.join(''));
            return F(ctx)();
        } catch (e) {
            if (debug) {
                console.log(e.name, 'in', funcBody);
            }
        }
        return this.calculationGoneWrongValue;
    }

    isValidPesel(pesel) {
        let weight = [1, 3, 7, 9, 1, 3, 7, 9, 1, 3];
        let sum = 0;
        let controlNumber = parseInt(pesel.substring(10, 11));

        for (let i = 0; i < weight.length; i++) {
            sum += (parseInt(pesel.substring(i, i + 1)) * weight[i]);
        }
        sum = sum % 10;
        return (10 - sum) % 10 === controlNumber;
    }

    minimumPasswordComplexity() {
        return 3;
    }

    passwordComplexity(password, minPasswordLength) {
        let num = {};
        num.Excess = 0;
        num.Upper = 0;
        num.Numbers = 0;
        num.Symbols = 0;

        let bonus = {};
        bonus.Excess = 3;
        bonus.Upper = 4;
        bonus.Numbers = 5;
        bonus.Symbols = 5;
        bonus.Combo = 0;
        bonus.FlatLower = 0;
        bonus.FlatNumber = 0;

        let totalScore = 0;
        let baseScore = 50;

        minPasswordLength = minPasswordLength || 6;

        if (password.length >= minPasswordLength) {
            for (let i = 0; i < password.length; i++) {
                if (password[i].match(/[A-Z]/g)) { num.Upper++; }
                if (password[i].match(/[0-9]/g)) { num.Numbers++; }
                if (password[i].match(/(.*[!,@,#,$,%,^,&,*,?,_,~])/)) { num.Symbols++; }
            }

            num.Excess = password.length - minPasswordLength;

            if (num.Upper && num.Numbers && num.Symbols) {
                bonus.Combo = 25;
            }

            else if ((num.Upper && num.Numbers) || (num.Upper && num.Symbols) || (num.Numbers && num.Symbols)) {
                bonus.Combo = 15;
            }

            if (password.match(/^[\sa-z]+$/)) {
                bonus.FlatLower = -15;
            }

            if (password.match(/^[\s0-9]+$/)) {
                bonus.FlatNumber = -35;
            }

            totalScore = baseScore;
            totalScore += (num.Excess * bonus.Excess);
            totalScore += (num.Upper * bonus.Upper);
            totalScore += (num.Numbers * bonus.Numbers);
            totalScore += (num.Symbols * bonus.Symbols);
            totalScore += bonus.Combo;
            totalScore += bonus.FlatLower;
            totalScore += bonus.FlatNumber;

        }
        else {
            baseScore = 0;
        }

        if (password.length < minPasswordLength) {
            return 0;
        }
        else if (totalScore < 50) {
            return 1;
        }
        else if (totalScore >= 50 && totalScore < 75) {
            return 2;
        }
        else if (totalScore >= 75 && totalScore < 100) {
            return 3;
        }
        else if (totalScore >= 100) {
            return 4;
        }
    }

    provincesOf(country) {
        // https://en.wikipedia.org/wiki/Category:Administrative_divisions_in_Europe
        switch (country.toLowerCase()) {
            case 'poland':
                return [
                    "Dolnośląskie",
                    "Kujawsko - pomorskie",
                    "Lubelskie",
                    "Lubuskie",
                    "Łódzkie",
                    "Małopolskie",
                    "Mazowieckie",
                    "Opolskie",
                    "Podkarpackie",
                    "Podlaskie",
                    "Pomorskie",
                    "Śląskie",
                    "Świętokrzyskie",
                    "Warmińsko - mazurskie",
                    "Wielkopolskie",
                    "Zachodniopomorskie",
                ];

            case 'sweden':
                return [
                    'Stockholm',
                    'Västerbotten',
                    'Norrbotten',
                    'Uppsala',
                    'Södermanland',
                    'Östergötland',
                    'Jönköping',
                    'Kronoberg',
                    'Kalmar',
                    'Gotland',
                    'Blekinge',
                    'Skåne',
                    'Halland',
                    'Västra Götaland',
                    'Värmland',
                    'Örebro',
                    'Västmanland',
                    'Dalarna',
                    'Gävleborg',
                    'Västernorrland',
                    'Jämtland'
                ];

            case 'germany':
                return [
                    'Baden - Württemberg',
                    'Bayern',
                    'Berlin',
                    'Brandenburg',
                    'Bremen',
                    'Hamburg',
                    'Hessen',
                    'Niedersachsen',
                    'Mecklenburg - Vorpommern',
                    'Nordrhein - Westfalen',
                    'Rheinland - Pfalz',
                    'Saarland',
                    'Sachsen',
                    'Sachsen - Anhalt',
                    'Schleswig - Holstein',
                    'Thüringen'
                ];

            case 'italy':
                return [
                    'Nord-Ovest',
                    'Nord-Est',
                    'Centro',
                    'Sud',
                    'Isole or Insulare'
                ];

            default:
                return [];
        }
    }
}

export default Utils