class API {

    constructor() {
        this.config = {
            debug: 1,
            url: undefined,
            env: undefined,
            xhrAbort: null,
            data: [],
            timeOffset: 0,
            progressFactor: 1,
            headers: []
        }
        this.token = {
            name: "Authorization",
            type: "Bearer",
            value: null,
            storage: localStorage,
            key: 'token'
        }
    }

    isDef(v) {
        return typeof v !== "undefined";
    }

    dispatchEvent(key, value) {
        var eventName = key;
        window.dispatchEvent(new CustomEvent(eventName, { 'detail': value }), true);
    }

    getConfig(key) {
        return this.config[key] || null;
    }

    setConfig(key, value) {
        this.config[key] = value;
        return this.config[key] || null;
    }

    setToken(value) {
        var self = this;
        self.token.value = value;
        self.token.storage.setItem(self.token.key, JSON.stringify(self.token.value));
    }

    getToken() {
        var self = this;
        self.token.value = JSON.parse(self.token.storage.getItem(self.token.key));
        return self.token.value;
    }

    syncTime(ts) {
        var self = this;
        var resolve = function (data) {
            var dateStr = data.time.date;
            var serverTimeMillisGMT = Date.parse(new Date(Date.parse(dateStr)).toUTCString());
            var localMillisUTC = Date.parse(new Date().toUTCString());
            self.config.timeOffset = serverTimeMillisGMT - localMillisUTC;
        }
        resolve({ time: { date: ts } });
    }

    utcToLocal(date) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
        var offset = date.getTimezoneOffset() / 60;
        var hours = date.getHours();
        newDate.setHours(hours - offset);
        return newDate;
    }

    getTime(asTimestamp) {
        var date = new Date();
        date.setTime(date.getTime() + this.config.timeOffset);
        var local = this.utcToLocal(date);
        if (asTimestamp) {
            return parseInt((local.getTime() / 1000).toFixed(0));
        }
        return local;
    }

    setCookie(sName, sValue, options) {
        var sCookie = encodeURIComponent(sName) + '=' + encodeURIComponent(sValue);
        if (options && options instanceof Date) {
            options = {
                expires: options
            };
        }
        if (options && typeof options == 'object') {
            if (options.expires) {
                sCookie += '; expires=' + options.expires.toGMTString();
            }
            if (options.path) {
                sCookie += '; path=' + options.path.toString();
            }
            if (options.domain) {
                sCookie += '; domain=' + options.domain.toString();
            }
            if (options.secure) {
                sCookie += '; secure';
            }
        }
        document.cookie = sCookie;
    }

    getCookie(sName) {
        var oCrumbles = document.cookie.split(';');
        for (var i = 0; i < oCrumbles.length; i++) {
            var oPair = oCrumbles[i].split('=');
            var sKey = decodeURIComponent(oPair[0].trim());
            var sValue = oPair.length > 1 ? oPair[1] : '';
            if (sKey === sName) {
                return decodeURIComponent(sValue);
            }
        }
        return '';
    }

    removeCookie(sName, options) {
        if (!options) {
            options = {};
        }
        options.expires = new Date();
        this.setCookie(sName, '', options);
    }

    uuidv4() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = Math.random() * 16 | 0, v = c === 'x' ? r : ((r & 0x3) | 0x8);
            return v.toString(16);
        });
    }

    isGuid(stringToTest) {
        if (stringToTest[0] === "{") {
            stringToTest = stringToTest.substring(1, stringToTest.length - 1);
        }
        var regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}(\}){0,1}$/gi;
        return regexGuid.test(stringToTest);
    }

    abort() {
        this.xhrAbort = Date.now();
    }

    endpoint(endpoint) {
        return this.config.url + endpoint.replace(/^\/+/g, '');
    }

    get(endpoint, resolveFn, rejectFn, progressFn) {
        return this.request(endpoint, {}, resolveFn, rejectFn, progressFn, 'GET');
    }

    post(endpoint, data, resolveFn, rejectFn, progressFn) {
        return this.request(endpoint, data, resolveFn, rejectFn, progressFn, 'POST');
    }

    request(endpoint, data, resolveFn, rejectFn, progressFn, method) {
        var self = this;
        var xhrCreated = Date.now();
        var xhr = new XMLHttpRequest();
        var url = "";

        var isAborted = function () {
            var test = this.xhrAbort > xhrCreated;
            return test;
        }.bind(this);

        if (typeof data == "undefined") {
            data = {};
        }

        if (typeof method == "undefined") {
            method = 'POST';
        }

        var rejected = false;

        var progress = function (data) {
            self.dispatchEvent('API_PROGRESS', parseFloat(data) * self.config.progressFactor);
            if (typeof progressFn === "function") {
                if (!isAborted()) {
                    progressFn(data);
                }
            }
        }

        var resolve = function (data) {
            progress(100);
            if (typeof resolveFn === "function") {
                if (!isAborted()) {
                    resolveFn(data)
                }
            }
        }

        var reject = function (data) {
            if (rejected) {
                return;
            } else {
                rejected = true;
            }
            progress(100);
            if (self.isDef(data) && self.config.debug) {
                console.error("api.js", JSON.stringify(data));
            }
            self.dispatchEvent('API_STATUS', data.status);
            if (typeof rejectFn === "function") {
                if (!isAborted()) {
                    rejectFn(data);
                }
            }
        }

        progress(0);

        url = self.endpoint(endpoint);

        xhr.open(method, url, true);

        if (typeof data.files !== "undefined") {
            let formData = new FormData();
            for (var f = 0; f < data.files.length; f++) {
                formData.append('files[]', data.files[f], data.files[f].name);
            }
            delete data.files;
            formData.append('_input', JSON.stringify(data));
            data = formData;
        } else {
            xhr.setRequestHeader('Content-Type', 'application/json');
            data = JSON.stringify(data);
        }

        if (self.token.value) {
            xhr.setRequestHeader(self.token.name, self.token.type + ' ' + self.token.value);
        }

        if (self.config.headers) {
            for (var h = 0; h < self.config.headers.length; h++) {
                xhr.setRequestHeader(self.config.headers[h].name, self.config.headers[h].value);
            }
        }

        xhr.setRequestHeader('Accept', 'application/json');

        xhr.onprogress = function (event) {
            if (event.lengthComputable) {
                var complete = (event.loaded / event.total * 100 | 0);
                progress(complete);
            }
        }

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                try {
                    var obj = JSON.parse(xhr.response);
                    if (obj.meta.success) {
                        return resolve(obj.data);
                    } else {
                        return reject({
                            ident: obj.meta.ident,
                            data: obj.data,
                        });
                    }
                } catch (error) {
                    return reject({
                        ident: "xhr.onreadystatechange",
                        status: xhr.status,
                        data: error
                    });
                }
            }
        };

        xhr.onerror = function () {
            return reject({
                ident: "xhr.onerror",
                status: xhr.status,
                statusText: xhr.statusText
            });
        };

        xhr.send(data);

    }

}

export default API;