import { React, Component } from 'react';
import logo from './../assets/img/logo.svg';
import { Container, Row, Col } from "react-bootstrap";

class NotFound extends Component {

    render() {
        return (
            <Container fluid className="bg-grey-1">
                <Row className="h-100 justify-content-center align-items-center rui-sign">
                    <Col xs={12} sm={10} md={8} lg={8} xl={6}>
                        <div className="rui-sign-form-cloud">
                            <Row>
                                <Col xs={12} sm={12} md={12} lg={12} className="pb-30">
                                    <div className="logo">
                                        <img src={logo} className="img-fluid" alt="" />
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={12} lg={12}>
                                    <h1>Page not found.</h1>
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        )
    }

}

export default NotFound;