import { React, Component } from 'react';
import logo from './../assets/img/logo.svg';
import ribbon from './../assets/img/ribbon.png';
import { Container, Row, Col, Form, Button, Modal, Alert, Spinner } from "react-bootstrap";
import { HelpCircle } from "react-feather";
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

class ZgloszeniePartneraSunroofPl extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeStep: 0,
            modal: false,

            alertVariant: 'danger',
            alert: '',

            loading: false,
            autoFilled: false,

            preferredCooperationModel: 'ambassador',
            preferredCooperationType: 'architect',
            preferredCooperationDescription: '',

            representsCompany: true,

            companyTaxNumber: '',
            companyIdentNumber: '',
            personalIdentNumber: '',
            companyName: '',

            postalCode: '',
            cityName: '',
            streetAddress: '',
            streetNumber: '',
            apartmentNumber: '',
            countryCode: '',
            provinceName: '',

            firstName: '',
            lastName: '',
            emailAddress: '',
            mobilePhone: '',

            otp: '',
            password: '',
            passwordRepeat: '',
            passwordComplexity: 0,

            withReferralCode: false,
            referralCode: '',

            consentAgreement: true,
            consentSalesLeaderContest: false,
            consentAffIsEmployed: null,
            consentAffIsContractor: null,
            consentAffIsStudent: null,
            consentAffIsUnemployed: null,
            consentAffIsRetired: null,
        }
    }

    nextStep(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            activeStep: this.state.activeStep + 1
        })
    }

    prevStep(e) {
        if (e) {
            e.preventDefault();
        }
        this.setState({
            activeStep: this.state.activeStep - 1
        })
    }

    handleLoading(msg) {
        this.setState({
            alert: msg || 'Trwa sprawdzanie danych...',
            alertVariant: 'info',
            loading: true
        });
    }

    handleLoaded() {
        this.setState({
            loading: false,
            alert: '',
            alertVariant: 'info'
        });
    }

    handleError(errorObject) {
        let alert;
        switch (errorObject.data.error.ident) {
            case "error_missing_required":
            case "error_bad_value_of_required":
                switch (errorObject.data.details) {
                    case "user/mobilePhone":
                        alert = "Wprowadź poprawny numer telefonu.";
                        break;
                    case "user/consentAgreement":
                    case "user/consentSalesLeaderContest":
                        alert = "Musisz wyrazić zgodę na przetwarzanie danych.";
                        break;
                    default:
                        alert = "Podaj poprawne dane.";
                        break
                }
                break;
            case "No correct phone numbers":
            case "Empty list of valid recipients. Numbers might be on black or opt-out list or no country prefix specified.":
                alert = "Wprowadź poprawny numer telefonu.";
                break;
            case "error_user_already_registered":
                alert = "Podany adres e-mail został już zarejestrowany.";
                break;
            case "error_wrong_otp":
                alert = "Podany token jest błędny."
                break;
            case "error_expired_otp":
                alert = "Podany token wygasł. Wróć i spróbuj jeszcze raz.";
                break;
            default:
                alert = errorObject.data.error.ident
                break;
        }
        this.setState({
            alert: alert,
            alertVariant: 'danger',
            loading: false,
        });
    }

    checkReferralCodeAndNipOrPesel() {
        this.handleLoading();
        this.checkReferralCode(
            () => {
                if (this.state.representsCompany) {
                    this.checkNip();
                } else {
                    this.checkPesel();
                }
            }
        )
    }

    checkPesel() {
        this.handleLoading();
        setTimeout(() => {
            if (!this.props.utils.isValidPesel(this.state.personalIdentNumber)) {
                this.handleError({ data: { error: 'Został podany błędny numer PESEL.' } });
            } else {
                this.handleLoaded();
                this.nextStep();
            }
        }, 600);
    }

    checkReferralCode(callback) {
        if (!this.state.withReferralCode) {
            callback();
            return;
        }
        this.props.api.post(
            `/gate/referral-code-search`,
            {
                "user": {
                    "referralCode": this.state.referralCode
                }
            },
            (data) => {
                this.setState({
                    referralCode: data.user.referralCode,
                    loading: false,
                    alert: ''
                });
                callback();
            },
            () => {
                this.setState({
                    alert: 'Błędny kod polecający.',
                    alertVariant: 'danger',
                    loading: false
                });
            }
        )
    }

    checkNip() {
        this.props.api.post(
            `/gate/company-search`,
            {
                "company": {
                    "taxNumber": this.state.companyTaxNumber
                }
            },
            (data) => {
                data = data[0];
                this.setState({
                    companyTaxNumber: data.taxNumber,
                    companyIdentNumber: data.identNumber,
                    companyName: data.name,
                    postalCode: data.zipCode,
                    cityName: data.city,
                    streetAddress: data.street,
                    streetNumber: data.propertyNumber,
                    apartmentNumber: data.apartmentNumber,
                    provinceName: data.province?.toLowerCase(),
                    loading: false,
                    autoFilled: true,
                    alert: ''
                });
                this.nextStep();
            },
            () => {
                this.setState({
                    alert: 'Numer NIP nie został znaleziony w bazie GUS.',
                    alertVariant: 'danger',
                    loading: false,
                    autoFilled: false
                });
            }
        )
    }

    sendOtp() {
        this.handleLoading();
        this.props.api.post(
            `/auth/pre-register`,
            {
                "user": {
                    "emailAddress": this.state.emailAddress,
                    "mobilePhone": this.state.mobilePhone
                }
            },
            (data) => {
                this.handleLoaded();
                this.nextStep();
            },
            (errorResponse) => {
                this.handleError(errorResponse)
            }
        )
    }

    register() {
        this.handleLoading();
        if (this.state.passwordComplexity < this.props.utils.minimumPasswordComplexity()) {
            this.handleError({ data: { error: 'Podane hasło jest za słabe.' } });
            return;
        }
        if (this.state.password !== this.state.passwordRepeat) {
            this.handleError({ data: { error: 'Podane hasła nie są zgodne.' } });
            return;
        }
        this.props.api.post(
            `/auth/register`,
            {
                "token": {
                    "otp": this.state.otp
                },
                "user": {
                    "referralCode": this.state.withReferralCode ? this.state.referralCode : null,
                    "emailAddress": this.state.emailAddress,
                    "preferredCooperationModel": this.state.preferredCooperationModel,
                    "preferredCooperationType": this.state.preferredCooperationType,
                    "preferredCooperationDescription": this.state.preferredCooperationDescription,
                    "firstName": this.state.firstName,
                    "lastName": this.state.lastName,
                    "mobilePhone": this.state.mobilePhone,
                    "representsCompany": this.state.representsCompany,
                    "taxNumber": this.state.taxNumber,
                    "identNumber": this.state.personalIdentNumber,
                    "password": this.state.password,
                    "consentAgreement": this.state.consentAgreement,
                    "consentSalesLeaderContest": this.state.consentSalesLeaderContest,
                    "consentAffIsEmployed": this.state.consentAffIsEmployed,
                    "consentAffIsContractor": this.state.consentAffIsContractor,
                    "consentAffIsStudent": this.state.consentAffIsStudent,
                    "consentAffIsUnemployed": this.state.consentAffIsUnemployed,
                    "consentAffIsRetired": this.state.consentAffIsRetired,
                },
                "address": {
                    "postalCode": this.state.postalCode,
                    "cityName": this.state.cityName,
                    "streetAddress": this.state.streetAddress,
                    "streetNumber": this.state.streetNumber,
                    "apartmentNumber": this.state.apartmentNumber,
                    "provinceName": this.state.provinceName,
                    "countryCode": this.state.countryCode
                },
                "company": {
                    "name": this.state.companyName,
                    "taxNumber": this.state.companyTaxNumber,
                    "identNumber": this.state.companyIdentNumber
                },
                "message": {
                    "name": "partner-welcome-pl"
                }
            },
            () => {
                this.handleLoaded();
                this.nextStep();
            },
            (errorResponse) => {
                this.handleError(errorResponse);
            }
        )
    }

    onSubmit(e) {
        e.preventDefault();
        let delta = 0;
        if (this.state.preferredCooperationModel === 'ambassador' && !this.state.representsCompany) {
            delta = 1;
        }
        switch (this.state.activeStep) {
            case 0:
                this.checkReferralCodeAndNipOrPesel();
                break;
            case 2 + delta:
                this.sendOtp();
                break;
            case 3 + delta:
                this.register();
                break;
            case 4 + delta:
                window.location.href = "https://sunroof.se/pl/";
                break;
            default:
                this.nextStep(e);
                break;
        }
    }

    getPrimaryBtn(label) {
        return (
            <Button variant="primary" type="submit" block disabled={this.state.loading}>
                {label}
                {' '}
                {
                    this.state.loading &&
                    (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                            className="ml-10"
                        />
                    )
                }
            </Button>
        )
    }

    render() {

        let steps = [];
        let preferredCooperationTypes = [
            {
                "key": "architect",
                "value": "Architekt",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "designer",
                "value": "Projektant",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "constructionManager",
                "value": "Kierownik Budowy",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "generalContractor",
                "value": "Generalny Wykonawca",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "contractor",
                "value": "Wykonawca",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "developer",
                "value": "Deweloper",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "roofingMaterialDistributor",
                "value": "Dystrybutor pokryć dachowych",
                "apply": ["ambassador", "partner", "wholesaler"]
            },
            {
                "key": "distributorOfBuildingMaterialsAndRoofing",
                "value": "Dystrybutor materiałów budowlanych i pokryć dachowych",
                "apply": ["ambassador", "partner", "wholesaler"]
            },
            {
                "key": "distributorOfProducts",
                "value": "Dystrybutor produktów (np. panele PV, pompy ciepła, maty grzewcze, dom inteligentny)",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "bankingCreditAndConsultingActivities",
                "value": "Działalność bankowa, kredytowa, doradcza",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "employeeLocalGovernmentInstitution",
                "value": "Sektor Publiczny",
                "apply": ["ambassador", "partner"]
            },
            {
                "key": "roofer",
                "value": "Dekarz",
                "apply": ["contractor"]
            },
            {
                "key": "carpenter",
                "value": "Cieśla",
                "apply": ["contractor"]
            },
            {
                "key": "electrician",
                "value": "Elektryk",
                "apply": ["contractor"]
            },
            {
                "key": "bricklayer",
                "value": "Murarz",
                "apply": ["contractor"]
            },
            {
                "key": "other",
                "apply": ["ambassador", "partner"],
                "value": "Inne"
            }
        ].filter(item => item.apply.includes(this.state.preferredCooperationModel));

        steps.push(
            <>
                <Form.Group>
                    <Form.Group controlId="preferredCooperationModel">
                        <Form.Label>Jaki charakter współpracy preferujesz?</Form.Label>
                        <Form.Control value={this.state.preferredCooperationModel} as="select" onChange={(e) => { this.setState({ preferredCooperationModel: e.target.value, preferredCooperationType: preferredCooperationTypes[0].key }) }} disabled={this.state.loading}>
                            <option value="ambassador">Ambasador</option>
                            {/* <option value="partner">Partner</option> */}
                            <option value="wholesaler">Dystrybutor</option>
                            <option value="contractor">Wykonawca</option>
                        </Form.Control>
                    </Form.Group>
                </Form.Group>

                <Form.Group>
                    <Form.Group controlId="preferredCooperationType">
                        <Form.Label>Jaki jest profil Twojej działalności?</Form.Label>
                        <Form.Control value={this.state.preferredCooperationType} as="select" onChange={(e) => { this.setState({ preferredCooperationType: e.target.value }) }} disabled={this.state.loading}>
                            {preferredCooperationTypes.map(itm => {
                                return (
                                    <option key={itm.key} value={itm.key}>{itm.value}</option>
                                )
                            })}
                        </Form.Control>
                    </Form.Group>
                </Form.Group>

                {
                    this.state.preferredCooperationType === "other" && (
                        <Form.Group>
                            <Form.Label>Profil Twojej działalności:</Form.Label>
                            <Form.Control required type="text" placeholder="Wprowadź opis" value={this.state.preferredCooperationDescription} onChange={(e) => { this.setState({ preferredCooperationDescription: e.target.value }) }} />
                        </Form.Group>
                    )
                }

                <Form.Group controlId="representsCompany" className="mb-4">
                    <Form.Check type="checkbox" label="Reprezentuję firmę" checked={this.state.representsCompany || false} onChange={(e) => { this.setState({ representsCompany: !this.state.representsCompany }) }} disabled={this.state.loading} />
                </Form.Group>

                {
                    this.state.representsCompany
                        ?
                        (
                            <Form.Group>
                                <Form.Control required type="text" placeholder="Wprowadź NIP" value={this.state.companyTaxNumber} onChange={(e) => { this.setState({ companyTaxNumber: e.target.value }) }} disabled={this.state.loading} />
                            </Form.Group>
                        )
                        :
                        (
                            <Form.Group>
                                <Form.Control required type="text" placeholder="Wprowadź PESEL" value={this.state.personalIdentNumber} onChange={(e) => { this.setState({ personalIdentNumber: e.target.value }) }} disabled={this.state.loading} />
                            </Form.Group>
                        )
                }

                <Form.Group controlId="withReferralCode" className="mb-4">
                    <Form.Check type="checkbox" label="Posiadam kod polecający" checked={this.state.withReferralCode || false} onChange={(e) => { this.setState({ withReferralCode: !this.state.withReferralCode }) }} disabled={this.state.loading} />
                </Form.Group>
                {
                    this.state.withReferralCode
                        ?
                        (
                            <Form.Group>
                                <Form.Control required type="text" placeholder="Wprowadź kod polecający" value={this.state.referralCode} onChange={(e) => { this.setState({ referralCode: e.target.value }) }} disabled={this.state.loading} />
                            </Form.Group>
                        )
                        :
                        (
                            <></>
                        )
                }

                <Row className="mt-30">
                    <Col>
                        {
                            this.getPrimaryBtn('Sprawdź')
                        }
                    </Col>
                </Row>
            </>
        );

        this.state.preferredCooperationModel === 'ambassador' && !this.state.representsCompany &&
            steps.push
                (
                    <>
                        <Form.Group>
                            <Form.Label>1. Jestem zatrudniony(a) na podstawie umowy o pracę lub równorzędnej, a moje wynagrodzenie ze stosunku pracy w kwocie brutto wynosi co najmniej minimalne wynagrodzenie:</Form.Label>
                            <br />
                            <Form.Check required id="consentAffIsEmployed1" name="consentAffIsEmployed" label="Tak" inline type={`radio`} checked={this.state.consentAffIsEmployed === true} onChange={(e) => { this.setState({ consentAffIsEmployed: true }) }} />
                            <Form.Check required id="consentAffIsEmployed2" name="consentAffIsEmployed" label="Nie" inline type={`radio`} checked={this.state.consentAffIsEmployed === false} onChange={(e) => { this.setState({ consentAffIsEmployed: false }) }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>2. Wykonuję zlecenie, umowę agencyjną lub inną umowę o świadczenie usług, do której zgodnie z Kodeksem cywilnym stosuje się przepisy dotyczące zlecenia, od której są odprowadzane składki emerytalno-rentowe od kwoty wynagrodzenia wynoszącej co najmniej minimalne wynagrodzenia za pracę:</Form.Label>
                            <br />
                            <Form.Check required id="consentAffIsContractor1" name="consentAffIsContractor" label="Tak" inline type={`radio`} checked={this.state.consentAffIsContractor === true} onChange={(e) => { this.setState({ consentAffIsContractor: true }) }} />
                            <Form.Check required id="consentAffIsContractor2" name="consentAffIsContractor" label="Nie" inline type={`radio`} checked={this.state.consentAffIsContractor === false} onChange={(e) => { this.setState({ consentAffIsContractor: false }) }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>3. Jestem studentem lub uczniem szkoły ponadpodstawowej do ukończenia 26 lat:</Form.Label>
                            <br />
                            <Form.Check required id="consentAffIsStudent1" name="consentAffIsStudent" label="Tak" inline type={`radio`} checked={this.state.consentAffIsStudent === true} onChange={(e) => { this.setState({ consentAffIsStudent: true }) }} />
                            <Form.Check required id="consentAffIsStudent2" name="consentAffIsStudent" label="Nie" inline type={`radio`} checked={this.state.consentAffIsStudent === false} onChange={(e) => { this.setState({ consentAffIsStudent: false }) }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>4. Jestem zarejestrowany(a) jako bezrobotny(a):</Form.Label>
                            <br />
                            <Form.Check required id="consentAffIsUnemployed1" name="consentAffIsUnemployed" label="Tak" inline type={`radio`} checked={this.state.consentAffIsUnemployed === true} onChange={(e) => { this.setState({ consentAffIsUnemployed: true }) }} />
                            <Form.Check required id="consentAffIsUnemployed2" name="consentAffIsUnemployed" label="Nie" inline type={`radio`} checked={this.state.consentAffIsUnemployed === false} onChange={(e) => { this.setState({ consentAffIsUnemployed: false }) }} />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>5. Mam ustalone prawo do emerytury lub renty:</Form.Label>
                            <br />
                            <Form.Check required id="consentAffIsRetired1" name="consentAffIsRetired" label="Tak" inline type={`radio`} checked={this.state.consentAffIsRetired === true} onChange={(e) => { this.setState({ consentAffIsRetired: true }) }} />
                            <Form.Check required id="consentAffIsRetired2" name="consentAffIsRetired" label="Nie" inline type={`radio`} checked={this.state.consentAffIsRetired === false} onChange={(e) => { this.setState({ consentAffIsRetired: false }) }} />
                        </Form.Group>
                        <Row className="mt-30">
                            <Col>
                                <Button variant="secondary" type="button" block onClick={(e) => { this.prevStep(e) }}>
                                    Wstecz
                                </Button>
                            </Col>
                            <Col>
                                {this.getPrimaryBtn('Dalej')}
                            </Col>
                        </Row>
                    </>
                );

        steps.push(
            <>

                {
                    this.state.representsCompany
                        ?
                        (
                            <>
                                <Form.Group>
                                    <Form.Label>Nazwa firmy:</Form.Label>
                                    <Form.Control readOnly={this.state.autoFilled} required type="text" placeholder="Wprowadź nazwę firmy" value={this.state.companyName} onChange={(e) => { this.setState({ companyName: e.target.value }) }} />
                                </Form.Group>
                                <Form.Group>
                                    <Form.Label>REGON:</Form.Label>
                                    <Form.Control readOnly={this.state.autoFilled} required type="text" placeholder="Wprowadź REGON" value={this.state.companyIdentNumber} onChange={(e) => { this.setState({ companyIdentNumber: e.target.value }) }} disabled={this.state.loading} />
                                </Form.Group>
                            </>
                        )
                        :
                        (
                            <></>
                        )
                }

                <Form.Group>
                    <Form.Group controlId="provinceName">
                        <Form.Label>Województwo:</Form.Label>
                        <Form.Control required readOnly={this.state.autoFilled} as="select" value={this.state.provinceName} onChange={(e) => { this.setState({ provinceName: e.target.value }) }} disabled={this.state.loading} >
                            <option value="" disabled>Wybierz z listy</option>
                            {
                                this.props.utils.provincesOf('Poland').map(
                                    province => {
                                        return <option value={province.toLowerCase()} key={province}>{province}</option>
                                    }
                                )
                            }
                        </Form.Control>
                    </Form.Group>
                </Form.Group>

                <Form.Row>
                    <Col md={5}>
                        <Form.Group>
                            <Form.Label>Kod pocztowy:</Form.Label>
                            <Form.Control readOnly={this.state.autoFilled} required type="text" placeholder="..." value={this.state.postalCode} onChange={(e) => { this.setState({ postalCode: e.target.value }) }} />
                        </Form.Group>
                    </Col>
                    <Col md={7}>
                        <Form.Group>
                            <Form.Label>Miasto:</Form.Label>
                            <Form.Control readOnly={this.state.autoFilled} required type="text" placeholder="Wprowadź miasto" value={this.state.cityName} onChange={(e) => { this.setState({ cityName: e.target.value }) }} />
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={12}>
                        <Form.Group>
                            <Form.Label>Ulica:</Form.Label>
                            <Form.Control readOnly={this.state.autoFilled} required type="text" placeholder="Wprowadź ulicę" value={this.state.streetAddress} onChange={(e) => { this.setState({ streetAddress: e.target.value }) }} />
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Form.Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Nr domu:</Form.Label>
                            <Form.Control readOnly={this.state.autoFilled} required type="text" placeholder="..." value={this.state.streetNumber} onChange={(e) => { this.setState({ streetNumber: e.target.value }) }} />
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Nr lokalu:</Form.Label>
                            <Form.Control readOnly={this.state.autoFilled} type="text" placeholder="..." value={this.state.apartmentNumber} onChange={(e) => { this.setState({ apartmentNumber: e.target.value }) }} />
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Row className="mt-30">
                    <Col>
                        <Button variant="secondary" type="button" block onClick={(e) => { this.prevStep(e) }}>
                            Wstecz
                        </Button>
                    </Col>
                    <Col>
                        {this.getPrimaryBtn('Dalej')}
                    </Col>
                </Row>
            </>
        );

        steps.push(
            <>
                <Form.Group>
                    <Form.Label>Imię:</Form.Label>
                    <Form.Control required type="text" placeholder="Wprowadź imię" value={this.state.firstName} onChange={(e) => { this.setState({ firstName: e.target.value }) }} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Nazwisko:</Form.Label>
                    <Form.Control required type="text" placeholder="Wprowadź nazwisko" value={this.state.lastName} onChange={(e) => { this.setState({ lastName: e.target.value }) }} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Adres e-mail:</Form.Label>
                    <Form.Control required type="email" placeholder="Wprowadź e-mail" value={this.state.emailAddress} onChange={(e) => { this.setState({ emailAddress: e.target.value }) }} />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Numer telefonu:</Form.Label>
                    <PhoneInput
                        required
                        international={true}
                        className="form-control"
                        defaultCountry="PL"
                        placeholder="Wprowadź numer telefonu"
                        value={this.state.mobilePhone}
                        onChange={(value) => { this.setState({ mobilePhone: value }) }} />
                    <Form.Text>Format: +48 123 456 789</Form.Text>
                </Form.Group>

                <Row className="mt-30">
                    <Col>
                        <Button variant="secondary" type="button" block onClick={(e) => { this.prevStep(e) }}>
                            Wstecz
                        </Button>
                    </Col>
                    <Col>
                        {this.getPrimaryBtn('Dalej')}
                    </Col>
                </Row>
            </>
        );

        steps.push(
            <>
                <Alert variant="info" className="mb-30">
                    Wysłaliśmy Ci kod bezpieczeństwa na podany numer telefonu lub adres e-mail. Wpisz go poniżej.
                </Alert>

                <Form.Group>
                    <Form.Label>Numer telefonu:</Form.Label>
                    <Form.Control required type="tel" placeholder="Wprowadź numer telefonu" value={this.state.mobilePhone} onChange={(e) => { this.setState({ emailAddress: e.target.value }) }} disabled />
                </Form.Group>

                <Form.Group>
                    <Form.Label>Token z wiadomości sms:</Form.Label>
                    <Form.Control required type="text" placeholder="Wprowadź token" value={this.state.otp} onChange={(e) => { this.setState({ otp: e.target.value }) }} />
                </Form.Group>

                <Form.Row>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Hasło:</Form.Label>
                            <Form.Control required type="password" placeholder="Hasło" autoComplete="new-password" value={this.state.password} onChange={(e) => { this.setState({ password: e.target.value, passwordComplexity: this.props.utils.passwordComplexity(e.target.value) }) }} />
                            <Form.Text>
                                {
                                    this.state.password && this.state.passwordComplexity < this.props.utils.minimumPasswordComplexity()
                                        ?
                                        `Hasło jest za słabe.`
                                        :
                                        ``
                                }
                            </Form.Text>
                        </Form.Group>
                    </Col>
                    <Col md={6}>
                        <Form.Group>
                            <Form.Label>Powtórz hasło:</Form.Label>
                            <Form.Control required type="password" placeholder="Hasło" autoComplete="new-password" value={this.state.passwordRepeat} onChange={(e) => { this.setState({ passwordRepeat: e.target.value }) }} />
                            <Form.Text>
                                {
                                    this.state.password && this.state.passwordRepeat && this.state.password !== this.state.passwordRepeat
                                        ?
                                        `Hasła nie są zgodne.`
                                        :
                                        ``
                                }
                            </Form.Text>
                        </Form.Group>
                    </Col>
                </Form.Row>

                {
                    this.state.preferredCooperationModel === 'wholesaler' &&
                    (
                        <Form.Group controlId="termsAndConditions">
                            <Form.Check type={`checkbox`} id={`terms`}>
                                <Form.Check.Input type={`checkbox`} checked={this.state.consentSalesLeaderContest || false} onChange={(e) => { this.setState({ consentSalesLeaderContest: !this.state.consentSalesLeaderContest }) }} />
                                <Form.Check.Label>Akceptuję treść <a target="_blank" rel="noreferrer" href="https://sunroof.se/wp-content/uploads/2021/02/Regulamin-Konkursu-SunRoof-Lider-Sprzedazy.docx.pdf">regulaminu Konkursu „SunRoof Lider Sprzedaży”</a></Form.Check.Label>
                            </Form.Check>
                        </Form.Group>
                    )
                }

                <Row className="mt-30">
                    <Col>
                        <Button variant="secondary" type="button" block onClick={(e) => { this.prevStep(e) }}>
                            Wstecz
                        </Button>
                    </Col>
                    <Col>
                        {this.getPrimaryBtn('Zapisz')}
                    </Col>
                </Row>
            </>
        );

        steps.push(
            <>
                <Alert variant="info" className="mb-30">
                    Dziękujemy za rejestrację. Za chwilę otrzymasz wiadomość z informacjami dotyczącymi logowania.
                </Alert>

                <Row className="mt-30">
                    <Col>
                        {this.getPrimaryBtn('Przedź do strony głównej')}
                    </Col>
                </Row>
            </>
        )

        return (
            <>

                <Modal
                    show={this.state.modal}
                    onHide={() => { this.setState({ modal: false }) }}
                    backdrop="static"
                    backdropClassName="better-backdrop"
                    keyboard={false}
                    centered
                >
                    <Modal.Header closeButton className="bg-primary">
                        <Modal.Title className="m-0 text-white">Skontaktuj się z nami</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p className="lead p-20">
                            E-mail: <strong>partnerzy@sunroof.se</strong>
                            <br />Infolinia dla Partnerów: <strong>+48 22 123 9111</strong>
                        </p>
                    </Modal.Body>
                </Modal>

                <Container fluid className="bg-grey-1 position-fixed p-0">
                    <Row className="vh-100 justify-content-left align-items-left p-0 m-0">
                        <Col sm={{ span: 4, offset: 0 }} md={{ span: 4, offset: 0 }} lg={{ span: 4, offset: 0 }} xl={{ span: 4, offset: 2 }} className="vh-100 p-0 d-none d-sm-block">
                            <img alt="" src={ribbon} className="h-100" />
                        </Col>
                    </Row>
                </Container>

                {
                    process.env.REACT_APP_ENVIRONMENT !== 'prod' && (
                        <div className="bg-warning text-center w-100 position-absolute" style={{ zIndex: 9999, fontSize: 10 }}>
                            ŚRODOWISKO TESTOWE - NIE WYKONUJ DZIAŁAŃ OPERACYJNYCH
                        </div>
                    )
                }

                <Container fluid className="position-absolute">

                    <Row className="h-100 justify-content-center align-items-center rui-sign">

                        <Col xs={12} sm={10} md={8} lg={8} xl={4}>

                            <div className="rui-sign-form-cloud">
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className="pb-30">
                                        <div className="logo">
                                            <img src={logo} className="img-fluid" alt="" />
                                        </div>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12} className="d-flex align-items-center">
                                        <h1>
                                            Formularz rejestracyjny&nbsp;({`${this.state.activeStep + 1}/${steps.length}`})
                                            {` `}
                                            <Button variant="link" className="p-5" onClick={(e) => { e.preventDefault(); this.setState({ modal: true }) }}>
                                                <HelpCircle />
                                            </Button>
                                        </h1>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12} className="">
                                        <p className="lead">
                                            Wypełnij formularz i dołącz do grona użytkowników systemu Sundesk.
                                        </p>
                                    </Col>

                                    <Col xs={12} sm={12} md={12} lg={12}>
                                        <Form method="post" onSubmit={(e) => { this.onSubmit(e) }}>
                                            {this.state.alert && (
                                                <Alert variant={this.state.alertVariant} className="mb-30">
                                                    {this.state.alert}
                                                </Alert>
                                            )}
                                            <SwitchTransition>
                                                <CSSTransition
                                                    key={this.state.activeStep}
                                                    timeout={300}
                                                    classNames="rui-router-transition"
                                                    unmountOnExit
                                                >
                                                    <div>
                                                        {steps[this.state.activeStep]}
                                                    </div>
                                                </CSSTransition>
                                            </SwitchTransition>
                                        </Form>
                                    </Col>
                                </Row>
                            </div>
                            <div className="mt-10 text-center">
                                <p className="mb-10 text-secondary">
                                    <small>
                                        v. {process.env.REACT_APP_VERSION}. © {new Date().getFullYear()} SunRoof International Holding AB.
                                    </small>
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </>
        );

    }
}

export default ZgloszeniePartneraSunroofPl;
