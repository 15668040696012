import { React, Component } from 'react';
import ZgloszeniePartneraSunroofPl from './domain/ZgloszeniePartneraSunroofPl.jsx';
import NotFound from './domain/NotFound.jsx';
import API from './api';
import Utils from './utils';

class App extends Component {

    constructor(props) {
        super(props);

        this.state = {
            domain: window.location.hostname.replace('www.', '')
        }

        this.domains = [
            {
                "domain": ["localhost", "master.db6g7it2o2so8.amplifyapp.com", "zgloszenie-partnera.bp.uat.sunroof.dev", "zgloszenie-partnera.sunroof.pl", "partner.sundesk.se"],
                "component": ZgloszeniePartneraSunroofPl,
                "headers": [
                    { "name": "x-region", "value": "poland" },
                    { "name": "x-lang", "value": "pl" }
                ]
            },
            {
                "domain": ["*"],
                "component": NotFound,
                "headers": [
                    { "name": "x-region", "value": "poland" },
                    { "name": "x-lang", "value": "en" }
                ]
            }
        ]

        this.view = this.domains.find(item => item.domain.includes(this.state.domain))
        if (!this.view) {
            this.view = this.domains.find(item => item.domain.includes('*'));
        }

        this.api = new API();
        this.api.setConfig("url", process.env.REACT_APP_API_URL);
        this.api.setConfig("headers", this.view.headers);
        this.utils = new Utils();
    }

    render() {
        let DomainComponent = this.view.component;
        return (
            <DomainComponent
                api={this.api}
                utils={this.utils}
            />
        )
    }
}

export default App;
